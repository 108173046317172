<template>
  <div>
    <div class="bg">

     
            <video playsInline controls autoplay id="video" class="vid1"></video>
            <!-- <video playsInline controls autoplay id="videos" class="vid2"></video> -->



  </div> 
    </div>
  </template>
  <script>
  import Peer from "simple-peer";
  export default {
    data() {
      return {
  jinru:1,//页面切换
  name:"",//用户的名字
  id:"",//自己的id
  all_name:[],//所有人的信息
     typp:1,//1的时候是视频，2是语音
  
     stream:null,//我的视频
     ystream:null,//对方的视频
  
      atream:null,//我的声音
     yatream:null,//对方的声音
      };
    },
    mounted() { 
      setTimeout(()=>{
        this.wang()
      },1000)       

     
    },
  
    methods: {

            wang() {
              // let $this = this;
            console.log(1,4566666666);
            console.log(1,navigator.mediaDevices.getUserMedia);

        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
          .then(function (streams) {
            
            console.log(1,4566666666666666666666666666666);
            console.log(streams);
            // $this.stream = streams;
            
          document.getElementById("video").srcObject=streams
            /* 使用这个 stream stream */
          })
          .catch(function (err) {
            console.log(err);
            /* 处理 error */
          });
      },

      
    },
  };
  </script>
  <style scoped>
  .vid2{
    width: 3.45rem;
    height: 7rem;
    position: absolute;
    right: 0;
    top: 0;
  }
  .vid1{
    width: 3.45rem;
    height: 7rem;
    position: absolute;
    left: 0;
    top: 0;
  }
  .spks{
  position: absolute;
  width: 7rem;
  height: 7rem;
  top: 1.8rem;
  left: 50%;
  transform: translate(-50%);
  background-color: #9a65cc;
  }
  .ank{
      width: 4.5rem;
    height: 0.8rem;
    position: absolute;
    top:10rem;
      left: 50%;
    transform: translate(-50%);
    display: flex;
    /* background-color: #6361ca; */
    justify-content:space-between;
  }
  .liebsfs{
    width: 2rem;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 0.3rem;
    font-size: 0.4rem;
    color: #ff3f0f;
    font-weight: bold;
    /* position: absolute; */
    /* top:10rem;
      left: 50%;
    transform: translate(-50%); */
    background-image: linear-gradient(62deg, #dbd2c5 0%, #ecc3d4 100%);
  }
  .liebsf{
    width: 2.8rem;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 0.3rem;
    font-size: 0.4rem;
    color: #ff3f0f;
    font-weight: bold;
    position: absolute;
    top:10rem;
      left: 50%;
    transform: translate(-50%);
    background-image: linear-gradient(62deg, #dbd2c5 0%, #ecc3d4 100%);
  }
  .liebss{
    font-size: 0.5rem;
    color: #e9baeb;
    /* font-weight: bold; */
    position: absolute;
    top:9rem;
      left: 50%;
    transform: translate(-50%);
  }
  .liebs{
    font-size: 0.5rem;
    color: #e9baeb;
    font-weight: bold;
    position: absolute;
    top:10.5rem;
      left: 50%;
    transform: translate(-50%);
  }
  .lieb{
    font-size: 0.5rem;
    color: #c814ff;
    font-weight: bold;
    position: absolute;
    top: 1rem;
      left: 50%;
    transform: translate(-50%);
  }
  .bs3{
    left: 2.1rem;
    font-size: 0.2rem;
    width: 1.2rem;
    line-height: 0.6rem;
  height: 0.6rem;
    position: absolute;
    top: 50%;
  transform: translateY(-50%);
  color: rgb(255, 255, 255);
   /* background-image: linear-gradient(62deg, #ff85e1 0%, #88d6d6 100%); */
  
  }
  .bs1{
    left: 3.4rem;
  }
  .bs2{
    left: 4.7rem;
  }
  .btns{
    position: absolute;
  width: 1.2rem;
  height: 0.6rem;
   background-image: linear-gradient(62deg, #ff85e1 0%, #88d6d6 100%);
  font-size: 0.2rem;
  border-radius: 0.2rem;
  line-height: 0.6rem;
  
  top: 50%;
  transform: translateY(-50%);
  /* left: 3.2rem; */
  }
  .nam{
    position: absolute;
    height: 0.8rem;
    width:3.1rem;
    font-size: 0.3rem;
    /* background-color: #cddfdc; */
    line-height: 0.8rem;
    text-align: left;
    padding-left: 0.2rem;
  }
  .lbxs{
    position: relative;
    height: 0.5rem;
    line-height: 0.5rem;
    font-size: 0.23rem;
    color: rgb(57, 205, 231);
    /* background-color: #8efceb; */
    /* border-bottom: 1px solid rgb(127, 190, 209); */
    
  }
  .lbx{
    position: relative;
    height: 0.8rem;
    /* background-color: #8efceb; */
    border-bottom: 1px solid rgb(127, 190, 209);
    
  }
  .lbk{
    overflow-Y: auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 6rem;
    height: 8rem;
    /* background-color: rebeccapurple; */
     background-image: linear-gradient(62deg, #b9fc82 0%, #6361ca 100%);
    top:2rem;
    border-radius: 0.1rem;
  }
  .spk{
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 6rem;
    height: 4rem;
    background-color: rebeccapurple;
    top: 1rem;
  }
  .bg .bgz {
    position: absolute;
    width: 100%;
    /* width:6.8rem ; */
    left: 50%;
    height: 13rem;
    top: 50%;
    transform: translate(-50%, -50%);
    /* overflow: auto; */
  }
  .bg {
  background-color: #8efceb;
    background-image: linear-gradient(62deg, #8efceb 0%, #e0c3fc 100%);
    position: absolute;
    width: 100%;
    height: 100vh;
    min-height: 13rem;
    background-size: cover;
    background-position: center top;
    /* max-height: 7rem; */
    /* max-width: 8rem; */
    left: 0rem;
    overflow: hidden;
  }
  
  
  .bgs .btn{
  background-image: linear-gradient(62deg, #ffec3e 0%, #9a65cc 100%);
    font-size: 0.5rem;
    position: absolute;
    top: 2rem;
      left: 50%;
    transform: translate(-50%);
    width: 3rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: 0.3rem;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
  .bgs .name{
    text-align: center;
    width: 5rem;
    height: 0.8rem;
    border: none;
    border-radius: 0.2rem;
    padding: 0.05rem 0.2rem;
    font-size: 0.4rem;
    position: absolute;
    /* top: 4.2rem; */
    left: 50%;
    transform: translate(-50%);
    color: rgb(70, 255, 255);
  }
  .bgs .namek{
  position: absolute;
    width: 6rem;
    height: 3rem;
    /* background-color: #8efceb; */
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* color: rgb(70, 255, 255); */
  }
  .bgs{
    background-image: linear-gradient(62deg, #8efceb 0%, #e0c3fc 100%);
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 2;
  }
  </style>